<template>
  <div>
    <v-chip :color="color" dark>{{ text }}</v-chip>
  </div>
</template>

<script>
export default {
  props: {
    status: String
  },

  data:() => ({
    color: "",
    text: "",
  }),

  mounted(){
    if(this.status == 'H'){
      this.color = "green"
      this.text = "Hadir"
    }
    if(this.status == 'I'){
      this.color = "secondary"
      this.text = "IZIN"
    }
    if(this.status == 'S'){
      this.color = "orange"
      this.text = "SAKIT"
    }
    if(this.status == 'A'){
      this.color = "red"
      this.text = "ALPA"
    }
  },
}
</script>
<template>
  <DefaultLayout>
    <template slot="toolbar">
      <slot name="appDrawer"></slot>
      <v-toolbar-title class="pl-1">
        <v-icon v-if="$store.state.pageIcon" class="pb-1 pr-2">{{ $store.state.pageIcon }}</v-icon>
        <span>{{ $store.state.pageTitle }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon @click="$refs.myRightBar.show()">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </template>

    <v-container>
      <div class="my-2">
        <h3 class="my-4">Informasi Kehadiran</h3>
        <p>{{ filterText }}</p>
        
        <div v-if="attendance.length==0" class="text-center"><v-icon size="60" class="mx-auto my-4">mdi-magnify</v-icon><p class="text-center">Oops.. data tidak dimukan, silahkan filter <a  @click="$refs.myRightBar.show()">disini</a>.</p></div>
        <v-card
          class="my-2"
          outlined
          elevation="4"
          v-for="(att, i) in attendance"
          :key="i"
        >
          <v-card-subtitle class="mb-0 pb-0">{{
            att.att_date
          }}</v-card-subtitle>
          <v-card-title class="pt-0">{{ att.subjects_name }}</v-card-title>
          <v-card-subtitle>{{ att.teacher_fullname }}</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col><AttendanceStatus :status="att.att_status"></AttendanceStatus></v-col>
            </v-row>
            <v-row v-if="att.reason != ''">
              <v-col>{{ att.reason }}</v-col>
            </v-row>
            <div></div>
          </v-card-text>
        </v-card>
      </div>
    </v-container>

    <RightBar ref="myRightBar" title="Filter">
      <v-dialog
        ref="myFilterStartDateDialog"
        v-model="filterStartDateDialog"
        :return-value.sync="filterStartDate"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="filterStartDate"
            label="Dari"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="filterStartDate" scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="filterStartDateDialog = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.myFilterStartDateDialog.save(filterStartDate)">
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>

      <v-dialog
        ref="myFilterEndDateDialog"
        v-model="filterEndDateDialog"
        :return-value.sync="filterEndDate"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="filterEndDate"
            label="Sampai"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="filterEndDate" scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="filterEndDateDialog = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.myFilterEndDateDialog.save(filterEndDate)">
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>

        <v-select
          v-model="attStatsSelect"
          :hint="`${attStatsSelect.val}, ${attStatsSelect.label}`"
          :items="attStats"
          label="Status"
          item-text="label"
          item-value="val"
          persistent-hint
          return-object
        ></v-select>

      <v-row class="mt-2">
        <v-col>
          <v-btn class="mr-1" color="primary" @click="getList">Filter</v-btn>
          <v-btn @click="resetFilter">Reset</v-btn>
        </v-col>
      </v-row>
    </RightBar>
  </DefaultLayout>
</template>

<script>
import AttendanceStatus from "@/components/AttendanceStatus.vue";
import RightBar from "@/layouts/RightBar.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";

export default {
  name: "Attendance",

  components: {
    AttendanceStatus,
    RightBar,
    DefaultLayout,
  },

  data: () => ({
    selectedItem: null,
    attendance: [],
    loading: null,
    attrs: {
      class: "mb-6",
      boilerplate: true,
      elevation: 2,
    },
    filterStartDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ).toISOString().substr(0, 10),
    filterStartDateDialog: false,
    filterEndDate: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    ).toISOString().substr(0, 10),
    filterEndDateDialog: false,
    filterText: '',
    attStatsSelect: {'val': '', 'label': 'Semua'},
    attStats:[
      {val: '', label: 'Semua'},
      {val: 'H', label: 'Hadir'},
      {val: 'I', label: 'Izin'},
      {val: 'S', label: 'Sakit'},
      {val: 'A', label: 'Alpa'},
    ]
  }),

  methods: {
    getList() {
      // this.$store.state.showLoadingDialog = true;
      this.loading = true;
      var config = {
        params: {
          att_status: this.attStatsSelect.val,
          start_date: this.filterStartDate,
          end_date: this.filterEndDate,
        },
      };
      this.$axios.get("attendance/list", config).then((res) => {
        // this.$store.state.showLoadingDialog = false;
        if (res.data.status == "success") {
          this.filterText = 'Menampilkan daftar kehadiran dari ' + res.data.data.start_date + ' sampai ' + res.data.data.end_date
          this.attendance = res.data.data.attendance;
        } else {
          this.attendance = [];
        }
        this.loading = false;
        this.$refs.myRightBar.hide();
      });
    },

    resetFilter(){

      this.filterText= ''
      var date = new Date();
      this.filterStartDate =  new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substr(0, 10);
      this.filterEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().substr(0, 10);

      this.attStatsSelect= {'val': '', 'label': 'Semua'}

      this.getList();

      this.$refs.myRightBar.hide();
    }
  },

  beforeMount(){
  },

  mounted() {
    this.resetFilter();
    this.getList();

    this.setPageTitle('Absensi', 'mdi-account-check')
  },
};
</script>
